import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Card from 'core/elements/card'
import { getFieldsForCard } from 'core/components/InfoPanel'
import InfoCard from 'k8s/components/common/entity/info-card'
import Progress from 'core/components/progress/Progress'
import { renderAgeFromTimestamp } from 'k8s/components/common/entity/helpers'
import Grid from 'core/elements/grid'
import { listPersistentVolumeClaims } from 'k8s/components/storage/persistent-volume-claims/new-actions'
import { makePersistentVolumeClaimSelector } from 'k8s/components/storage/persistent-volume-claims/selectors'
import { listDataVolumes } from 'k8s/components/storage/data-volumes/new-actions'
import { dataVolumesSelector } from 'k8s/components/storage/data-volumes/selectors'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { getVmStatus } from '../VmOverviewPage'
import Badge from 'core/elements/badge/Badge'
import { getDiskColumns, getDisks, IDisk } from './helpers'
import Text from 'core/elements/Text'
import EntityEventsPage from 'k8s/components/common/entity/entity-events-page'
import { getPodStatus } from 'k8s/components/pods/helpers'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import SimpleLink from 'core/components/SimpleLink'
import { IPodDetailsPageTabs } from 'k8s/components/pods/model'
import { routes } from 'core/utils/routes'
import { IVirtualMachineInstanceDetailsPageTabs } from '../vmi-model'
import InfoBlocks from 'core/components/entity-page/InfoBlocks'
import ResourceInfo from './ResourceInfo'
import LabelsAndAnnotationsSection from 'k8s/components/common/entity/labels-and-annotations/LabelsAndAnnotationsSection'
import { keys } from 'ramda'
import CardHeaderWithLink from 'core/elements/card/CardHeaderWithLink'
import EditNetworksButton from './EditNetworksButton'
import EditStorageButton from './EditStorageButton'
import EditResourcesButton from './EditResourcesButton'
import CardRefreshButton from './CardRefreshButton'
import GridDefaultCell from 'core/elements/grid/cells/GridDefaultCell'
import { listNetworks } from 'k8s/components/networking/network/actions'
import { networksSelector } from 'k8s/components/networking/network/selectors'
import { INetworkAttachmentDefinitionsDetailsPageTabs } from 'k8s/components/networking/network/model'
import { storageClassSelector } from 'k8s/components/storage/storage-classes/selectors'
import { listStorageClasses } from 'k8s/components/storage/storage-classes/new-actions'
import { DateAndTime } from 'core/components/listTable/cells/DateCell'
import { Condition } from '../model'
import { GridViewColumn } from 'core/elements/grid/Grid'

interface StyleProps {
  variant?: string
}

function PrimaryNetworkGridCell() {
  return (
    <GridDefaultCell>
      Pod Network <b>(primary)</b>
    </GridDefaultCell>
  )
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
  metadataTable: {
    borderSpacing: '20px',
    'last-child': {
      width: '100%',
    },
  },
  statusBadge: {
    display: 'inline-block',
    marginLeft: 8,
  },
  customCardHeader: {
    display: 'flex',
    padding: '16px 32px 0px',
    gap: 8,
    alignItems: 'center',
  },
  borderTop: {
    borderTop: `1px solid ${theme.components.table.border}`,
  },
  activePods: {
    display: 'grid',
    gap: 16,
  },
  activePod: {
    display: 'inline-grid',
    gap: 8,
    gridAutoFlow: 'column',
    alignItems: 'center',
  },
  podStatusCircle: {
    color: ({ variant }) => theme.components.badge[variant]?.color,
  },
  cardButtons: {
    display: 'inline-grid',
    gap: 16,
    gridAutoFlow: 'column',
    alignItems: 'center',
  },
}))

const pvcSelector = makePersistentVolumeClaimSelector()

export const renderStatus = (status) => {
  if (!status) return ''
  const { variant } = getVmStatus(status)
  return <Badge variant={variant} text={status} />
}

const renderRunStrategy = (value, item) =>
  value ? value : `None ${item?.spec?.running ? 'running=true' : 'running=false'}`

const PodStatusCircle = ({ status }) => {
  const { variant } = getPodStatus(status)
  const classes = useStyles({ variant })
  return (
    <FontAwesomeIcon className={classes.podStatusCircle} size="xs" solid>
      circle
    </FontAwesomeIcon>
  )
}

const ActivePods = ({ pods }) => {
  const classes = useStyles({})
  const activePods = pods?.filter((pod) => pod?.status?.phase !== 'Succeeded')
  return (
    <div className={classes.activePods}>
      {activePods.map((pod) => (
        <div key={pod?.name} className={classes.activePod}>
          <PodStatusCircle status={pod?.status?.phase} />
          <SimpleLink
            textVariant="body2"
            src={routes.kubevirtPods.details.path({
              clusterId: pod?.clusterId,
              id: pod?.id,
              tab: IPodDetailsPageTabs.Overview,
            })}
          >
            {pod?.name}
          </SimpleLink>
        </div>
      ))}
    </div>
  )
}

const renderPods = (value, item) => {
  return <ActivePods pods={value} />
}

const renderVmiReady = (conditions) => {
  const readyCondition = conditions.find((cond) => cond.type === 'Ready')
  return readyCondition?.status
}

export const getNetworkColumns = ({
  clusterId,
  networkIdsByName,
}: {
  clusterId: string
  networkIdsByName: { [key: string]: string }
}) => [
  {
    key: 'networkName',
    label: 'Network',
    render: (value: string) =>
      value === 'Pod Network' ? (
        <PrimaryNetworkGridCell />
      ) : (
        <SimpleLink
          lineClamp={3}
          src={routes.kubevirtNetworking.networkDetails.path({
            clusterId,
            id: networkIdsByName?.[value],
            tab: INetworkAttachmentDefinitionsDetailsPageTabs.Overview,
          })}
        >
          {value}
        </SimpleLink>
      ),
  },
  { key: 'ipAddress', label: 'IP Address', formatFn: (value: string) => (value ? value : 'N/A') },
  { key: 'mac', label: 'MAC Address', formatFn: (value: string) => (value ? value : 'N/A') },
]

const metadataFields = [
  {
    id: 'status.printableStatus',
    title: 'VM Status',
    render: renderStatus,
  },
  {
    id: 'vmi.status.conditions',
    title: 'VMI Ready',
    render: renderVmiReady,
  },
  {
    id: 'created',
    title: 'Created',
    required: true,
    render: renderAgeFromTimestamp,
  },
  {
    id: 'spec.runStrategy',
    title: 'Run Strategy',
    render: renderRunStrategy,
  },
  {
    id: 'instanceType.name',
    title: 'Instance Type',
  },
  {
    id: 'vmi.podObjects',
    title: 'Pods',
    render: renderPods,
  },
]

const conditionsColumns: GridViewColumn<Condition>[] = [
  {
    key: 'type',
    label: 'Type',
    width: 'medium',
  },
  {
    key: 'status',
    label: 'Status',
  },
  { key: 'lastProbeTime', label: 'Last Probe Time', CellComponent: DateAndTime },
  { key: 'lastTransitionTime', label: 'Last Transition Time', CellComponent: DateAndTime },
  { key: 'reason', label: 'Reason', formatFn: (val: string) => (val ? val : '-') },
  { key: 'message', label: 'Message', formatFn: (val: string) => (val ? val : '-') },
]

const getCpuFields = (vm) => {
  if (vm?.cpu && keys(vm.cpu).length) {
    return vm.cpu
  } else if (vm?.vmi?.cpu && keys(vm?.vmi?.cpu).length) {
    return vm.vmi.cpu
  } else if (vm?.instanceType?.spec?.cpu) {
    return vm.instanceType.spec.cpu
  }
  return {}
}

const getMemoryFields = (vm) => {
  if (vm?.memory && keys(vm.memory).length) {
    return vm.memory
  } else if (vm?.vmi?.memory && keys(vm?.vmi?.memory).length) {
    return vm.vmi.memory
  } else if (vm?.instanceType?.spec?.memory) {
    return vm.instanceType.spec.memory
  }
  return {}
}

export default function Overview({ vm, loading }) {
  const classes = useStyles({})
  const params = useMemo(
    () => ({
      clusterId: vm?.clusterId,
      namespace: vm?.namespace,
    }),
    [vm],
  )

  const { loading: loadingDataVolumes, reload: reloadDataVolumes } = useListAction(
    listDataVolumes,
    {
      params,
      requiredParams: ['clusterId'],
    },
  )
  const dataVolumes = useSelectorWithParams(dataVolumesSelector, params)

  const { loading: loadingPvcs, reload: reloadPvcs } = useListAction(listPersistentVolumeClaims, {
    params,
    requiredParams: ['clusterId'],
  })
  const pvcs = useSelectorWithParams(pvcSelector, params)

  const { loading: loadingStorageClasses, reload: reloadStorageClasses } = useListAction(
    listStorageClasses,
    {
      params,
      requiredParams: ['clusterId'],
    },
  )
  const storageClasses = useSelectorWithParams(storageClassSelector, params)

  const storageClassIdsByName = useMemo(() => {
    return storageClasses.reduce((accum, sc) => {
      return {
        ...accum,
        [sc?.name]: sc?.id,
      }
    }, {})
  }, [storageClasses])

  const { loading: loadingNetworks, reload: reloadNetworks } = useListAction(listNetworks, {
    params,
    requiredParams: ['clusterId', 'namespace'],
  })
  const networks = useSelectorWithParams(networksSelector, params)

  const networkIdsByName = useMemo(() => {
    return networks.reduce((accum, network) => {
      return {
        ...accum,
        [network?.name]: network?.id,
      }
    }, {})
  }, [networks])

  const diskColumns = getDiskColumns({ storageClassIdsByName })
  const networkColumns = getNetworkColumns({ clusterId: vm?.clusterId, networkIdsByName })

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, vm)
  }, [vm])

  const disks = useMemo(() => getDisks({ entity: vm, dataVolumes, pvcs }), [vm, dataVolumes, pvcs])

  const entities = useMemo(() => {
    const pods =
      vm?.vmi?.podIds?.map((id) => ({
        id,
      })) || []
    return [vm, vm?.vmi, ...pods].filter((entity) => !!entity)
  }, [vm])

  const overviewData = useMemo(
    () => [
      {
        label: 'Node',
        value: vm?.vmi?.status?.nodeName,
      },
      {
        label: 'Cluster',
        value: vm?.clusterName,
      },
      {
        label: 'Namespace',
        value: vm?.namespace,
      },
      {
        label: 'VM Instance',
        customValue: (
          <SimpleLink
            textVariant="caption1"
            src={
              vm?.vmi?.name &&
              routes.virtualMachineInstances.details.path({
                clusterId: vm?.vmi?.clusterId,
                name: vm?.vmi?.name,
                tab: IVirtualMachineInstanceDetailsPageTabs.Overview,
              })
            }
          >
            {vm?.vmi?.name}
          </SimpleLink>
        ),
      },
    ],
    [vm],
  )

  return (
    <Progress loading={loading}>
      <div className={classes.overview}>
        <div className={classes.column}>
          <Card
            title={
              <div className={classes.customCardHeader}>
                <Text variant="subtitle2">{vm.name}</Text>
                {renderStatus(vm?.status?.printableStatus)}
              </div>
            }
          >
            <Text variant="body2">Virtual Machine</Text>
            <InfoBlocks data={overviewData} />
          </Card>
          <InfoCard
            items={metadata}
            title="Properties"
            footer={<LabelsAndAnnotationsSection entity={vm} resourceType="vm" />}
          />
          <Card
            title={
              <CardHeaderWithLink
                linkComponent={
                  <div className={classes.cardButtons}>
                    <CardRefreshButton
                      onRefresh={() => {
                        reloadNetworks(true, true)
                      }}
                    />
                    <EditNetworksButton vm={vm} />
                  </div>
                }
              >
                Networks
              </CardHeaderWithLink>
            }
            withCustomBody
          >
            <Grid
              uniqueIdentifier="networkName"
              data={vm?.vmi?.networks || []}
              columns={networkColumns}
              loading={loading || loadingNetworks}
              compact
              disableSorting
              disableToolbar
            />
          </Card>
        </div>
        <div className={classes.column}>
          <Card
            title={
              <CardHeaderWithLink
                linkComponent={
                  <div className={classes.cardButtons}>
                    <EditResourcesButton vm={vm} />
                  </div>
                }
              >
                Resources {vm?.instanceType ? `(Instance Type ${vm?.instanceType.name})` : null}
              </CardHeaderWithLink>
            }
            withCustomBody
          >
            <ResourceInfo type="CPU" fields={getCpuFields(vm)} />
            <ResourceInfo
              className={classes.borderTop}
              type="Memory"
              fields={getMemoryFields(vm)}
            />
          </Card>
          <div className={classes.column}>
            <Card title="Status Conditions" withCustomBody>
              <Grid
                uniqueIdentifier="lastTransitionTime"
                data={vm?.status?.conditions || []}
                columns={conditionsColumns}
                loading={loading}
                disableToolbar
              />
            </Card>
          </div>
          <EntityEventsPage entity={entities} loading={loading} noMargin />
          <Card
            title={
              <CardHeaderWithLink
                linkComponent={
                  <div className={classes.cardButtons}>
                    <CardRefreshButton
                      onRefresh={() => {
                        reloadDataVolumes(true, true)
                        reloadPvcs(true, true)
                        reloadStorageClasses(true, true)
                      }}
                    />
                    <EditStorageButton vm={vm} />
                  </div>
                }
              >
                Disks
              </CardHeaderWithLink>
            }
            withCustomBody
          >
            <Grid<IDisk>
              uniqueIdentifier="name"
              data={disks}
              columns={diskColumns}
              loading={loadingDataVolumes || loadingPvcs || loadingStorageClasses}
              compact
              disableToolbar
            />
          </Card>
        </div>
      </div>
    </Progress>
  )
}
